import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import Box from 'reusecore/src/elements/Box';
import Card from 'reusecore/src/elements/Card';
import { useAuth } from '../contexts/AuthProvider';
import useMediaQuery from '../helpers/mediaQuery';
import withLayout from '../hoc/withLayout';
import PageMeta from '../components/PageMeta';
import React, {useEffect} from 'react';
import dynamic from 'next/dynamic';
import { Modal } from '@redq/reuse-modal';
import Seo from '../components/Seo';
import InfoBlocks from '../components/InfoBlock';
import TextSearch from '../containers/Home/TextSearch';
import CategoryPost from '../containers/Home/Categories';
import { useTranslation } from 'next-i18next';
import simplePage from '../serverProps/simplePage';
import RegisterBanner from '../components/RegistrationBlock';
import AppStore from '../components/AppStoreBlock';
import SkazyAdvertising from "../components/SkazyAdvertising";

// dynamic imports
const RecentPost = dynamic(() => import('../containers/Home/RecentPost'));

const BannerImage = require('@core/ui/static/images/home-banner.' +
  process.env.APP_LOCALIZATION +
  '.svg');
const logoImageVertical = require('@core/ui/static/images/logo-yatoo-vertical.' +
  process.env.APP_LOCALIZATION +
  '.png');

const InfoBlock = styled(InfoBlocks)`
  @media only screen and (max-width: 767px) {
    h2 {
      font-size: 19px;
      padding: 0;
    }

    p {
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 19px;
      padding-left: 30px;
      padding-right: 30px;
      margin: 0;
    }

    p {
      font-size: 15px;
    }
  }
`;

const Wrapper = styled('div')`
  background-image: url(${BannerImage});
  background-repeat: repeat-x;
  background-position: bottom center;
  padding-bottom: 20vh;
  margin-bottom: -11px;
  @media only screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
`;

const bannerStyle = {
  pt: [15],
  bg: '#FFF',
};

const Index = props => {
  const { isLoggedIn } = useAuth();

  const { t } = useTranslation();

  const isMobile = useMediaQuery('(max-width: 1023px)');
  const isTablet = useMediaQuery('(max-width: 1200px)');

  const canonical =
    process.env.PROJECT_ID === 'yatoo-nc' ? process.env.SITE_URL : null;

  const BannerGridWrapper = !isMobile
    ? ({ children }) => <Grid>{children}</Grid>
    : ({ children }) => <>{children}</>;

  return (
    <Wrapper>
      <PageMeta
        title={t(`${process.env.APP_LOCALIZATION}:home.head.title`)}
        description={t(`${process.env.APP_LOCALIZATION}:home.head.description`)}
        image={logoImageVertical}
        canonical={canonical}
      />
      <Card as="section" {...bannerStyle} className="main-content">
        <Grid style={{ padding: 0 }}>
          <Row>
            <Col
              mdOffset={1}
              sm={12}
              md={7}
              style={{ maxWidth: '100%' }}
              className="header"
            >
              <InfoBlock
                className="banner-infoblock"
                title={
                  t('home.body.title') +
                  t(`${process.env.APP_LOCALIZATION}:country.prefixFrom`)
                }
                highlight={t(`${process.env.APP_LOCALIZATION}:country.label`)}
                textAlign="center"
              />
              <TextSearch />
            </Col>
            <Col
              xs={12}
              sm={4}
              style={{justifyContent: 'flex-end', display: 'flex'}}
            >
              <SkazyAdvertising
                name="home"
                containerStyle={{display: isTablet ? 'none' : ''}}
                zone={{
                  id: process.env.SKAZY_AD_HOME_ID,
                  n: process.env.SKAZY_AD_HOME_N,
                }}
                height={250}
                width={300}
              />
            </Col>
          </Row>
          <CategoryPost/>
        </Grid>
        <div
          style={{
            height: '86px',
            backgroundImage: `url(${BannerImage})`,
            backgroundSize: 'auto 100%',
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'bottom center',
          }}
        />
      </Card>

      <Box
        pt={10}
        style={{
          display: isTablet ? 'flex' : 'none',
          justifyContent: 'center',
        }}
      >
        <SkazyAdvertising
          name="home"
          zone={{
            id: process.env.SKAZY_AD_HOME_ID,
            n: process.env.SKAZY_AD_HOME_N,
          }}
          height={250}
          width={300}
        />
      </Box>

      <Box as="section" pt={10} pb={0}>
        <Grid>
          <RecentPost />
        </Grid>
        {!isLoggedIn && (
          <BannerGridWrapper>
            <RegisterBanner isMobile={isMobile} />
          </BannerGridWrapper>
        )}
        <Grid>
          <Seo />
        </Grid>
        <BannerGridWrapper>
          <AppStore isMobile={isMobile} />
        </BannerGridWrapper>
      </Box>
      <Modal />
    </Wrapper>
  );
};

export default withLayout(Index);

export const getServerSideProps = simplePage;
