import React from 'react';
import styled from 'styled-components';

const InfoBlockWrapper = styled('div')`
  text-align: ${props => props.textAlign || 'left'};
`;
const Heading = styled('h2')`
  font-size: 23px;
  color: #0b2f3f;
  font-family: 'Poppins';
  font-weight: 600;
  text-align: left;
  padding-right: 100px;
  margin-bottom: 30px;
  margin-top: 18px;
  span {
    color: #00bcd4;
  }
`;
const Paragraph = styled('p')`
  line-height: ${props => props.lineHeight || 1.8};
  color: #595959;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
`;

const InfoBlock = ({
  className,
  textAlign,
  lineHeight,
  title,
  description,
  style,
  highlight,
}) => (
  <InfoBlockWrapper
    className={className}
    textAlign={textAlign}
    lineHeight={lineHeight}
    style={style}
  >
    <Heading>
      {title}
      <span>{highlight}</span>
    </Heading>
    <Paragraph dangerouslySetInnerHTML={{ __html: description }} />
  </InfoBlockWrapper>
);

export default InfoBlock;
