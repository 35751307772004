import { useQuery } from '@apollo/client';
import { GET_CATEGORIES_NUMBERS } from '@core/ui/graphql/Category.query';
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import Link from 'next/link';
import CategoryLoader from '../../../components/Loader/CategoryLoader';
import { CategoryGridCard as CategoryGridCardComp } from '../../../components/CategoryCard';
import ListGridComp from 'reusecore/src/elements/ListGrid';
import { SEARCH_PAGE } from '@core/ui/navigation/constant';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { slugify } from '../../../helpers/urlHandler';

import Car from '@core/ui/static/images/categories/img-voitures@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640';
import CarWebp from '@core/ui/static/images/categories/img-voitures@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640&format=webp';
import Dress from '@core/ui/static/images/categories/img-vetements@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640';
import DressWebp from '@core/ui/static/images/categories/img-vetements@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640&format=webp';
import Immo from '@core/ui/static/images/categories/img-immobilier@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640';
import ImmoWebp from '@core/ui/static/images/categories/img-immobilier@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640&format=webp';
import Mobilier from '@core/ui/static/images/categories/img-mobilier@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640';
import MobilierWebp from '@core/ui/static/images/categories/img-mobilier@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640&format=webp';
import Phone from '@core/ui/static/images/categories/img-telephonie@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640';
import PhoneWebp from '@core/ui/static/images/categories/img-telephonie@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640&format=webp';
import Kite from '@core/ui/static/images/categories/img-sports@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640';
import KiteWebp from '@core/ui/static/images/categories/img-sports@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640&format=webp';
import Computer from '@core/ui/static/images/categories/img-informatique@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640';
import ComputerWebp from '@core/ui/static/images/categories/img-informatique@2x.jpg?resize&sizes[]=160&sizes[]=320&sizes[]=640&format=webp';

const ListGrid = styled(ListGridComp)`
  justify-content: space-between;
  .singleGridBox {
    transition: all ease 0.3s;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
    &:first-child {
      margin-left: -7px;
    }
    &:last-child {
      margin-right: -7px;
    }
  }

  @media only screen and (max-width: 64em) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`;

const CategoryGridCard = styled(CategoryGridCardComp)`
  height: 100%;
  margin: 0 0.5rem;
  position: relative;

  img {
    border-radius: 8px;
    min-width: 100%;
    max-width: 100%;
  }

  h3 {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-shadow: 1px 1px 2px black;
  }

  @media only screen and (max-width: 64em) {
    background-color: transparent;
    border: 0;
    min-height: auto;
    img {
      max-width: initial;
    }
  }
`;

const Categories = styled(Row)`
  margin-top: 18px;
  h4 {
    color: #0b2f3f;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0;
  }
  .header-right {
    p {
      font-size: 15px;
      font-weight: 600;
      color: rgb(107, 129, 137);
      text-align: right;
      margin: 0;
    }
  }
  .categories {
    margin: 20px 0;
  }
  @media only screen and (max-width: 767px) {
    .header-right,
    .header-left {
      flex-basis: 100%;
      max-width: 100%;
    }
    .header-right {
      p {
        text-align: left;
        font-size: 13px;
        margin-top: 10px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    h2,
    p {
      padding-left: 30px;
    }
  }
`;

const _categories = [
  {
    name: 'home.search.categories.cars',
    link: 'Voitures',
    img: Car,
    webp: CarWebp,
  },
  {
    name: 'home.search.categories.phone',
    link: 'Téléphonie',
    img: Phone,
    webp: PhoneWebp,
  },
  {
    name: 'home.search.categories.house',
    link: 'Ventes Immobilières',
    img: Immo,
    webp: ImmoWebp,
  },
  {
    name: 'home.search.categories.dress',
    link: 'Vêtements',
    img: Dress,
    webp: DressWebp,
  },
  {
    name: 'home.search.categories.sports',
    link: 'Sports',
    img: Kite,
    webp: KiteWebp,
  },
  {
    name: 'home.search.categories.furniture',
    link: 'Mobilier',
    img: Mobilier,
    webp: MobilierWebp,
  },
  {
    name: 'home.search.categories.computer',
    link: 'Informatique',
    img: Computer,
    webp: ComputerWebp,
  },
];

const CategoryPost = () => {
  const { t } = useTranslation();

  const isMounted = useRef(false);

  const [categories, setCategories] = useState(_categories);

  const { data } = useQuery(GET_CATEGORIES_NUMBERS, {
    variables: {
      categories: _categories.map(cat => cat.link),
    },
    ssr: false,
  });

  const renderCategoryItem = item => {
    const title = item.name ? item.name : '';
    const img = item.img ? item.img : '';
    const webp = item.webp ? item.webp : '';
    const number = item.number ? item.number : '';

    const href = `/${SEARCH_PAGE}/${slugify(item.link)}`;

    return (
      <Link href={href}>
        <a style={{ height: '100%', display: 'block' }}>
          <CategoryGridCard
            imageSrc={img}
            webp={webp}
            sizes={
              '(min-width: 1200px) 160px, (min-width: 1040px) 125px, 160px'
            }
            title={t(title)}
            postNumber={number}
            hn="h3"
          />
        </a>
      </Link>
    );
  };

  useEffect(() => {
    isMounted.current = true;

    return () => void (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (data && data.categoriesNumbers && data.categoriesNumbers.data) {
      data.categoriesNumbers.data.forEach(res => {
        const found = _categories.find(cat => cat.link === res.name);
        if (found) {
          found.number = res.number.toString();
        }
      });

      if (isMounted.current) {
        setCategories([..._categories]);
      }
    }
  }, [data]);

  return (
    <Categories>
      <Col xs={6} className="header-left">
        <h2
          style={{
            fontSize: 20,
            color: 'rgb(11, 47, 63)',
            fontWeight: 600,
            margin: 0,
          }}
        >
          {t('category.popular')}
        </h2>
      </Col>
      <Col xs={6} className="header-right">
        <p>
          {t('category.all.label')}&nbsp;
          <Link href={`/${SEARCH_PAGE}/all`}>
            <a>{t('category.all.link')}</a>
          </Link>
        </p>
      </Col>
      <Col xs={12} className="categories">
        <ListGrid
          data={categories}
          columnWidth={[1, 1 / 2, 1 / 3, 1 / 7]}
          component={renderCategoryItem}
          placeholder={<CategoryLoader />}
          componentContainerStyle={{ p: 0 }}
          componentWrapperStyle={{
            ml: 0,
            mr: 0,
            flexBox: true,
            flexWrap: 'wrap',
          }}
        />
      </Col>
    </Categories>
  );
};

export default CategoryPost;
