import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'next/link';
import Card from 'reusecore/src/elements/Card';
import { useTranslation } from 'next-i18next';
import colors from '../../theme/colors';

const BannerImage = require('@core/ui/static/images/home-banner.' +
  process.env.APP_LOCALIZATION +
  '.svg');

const publishIcon = require('@core/ui/static/images/ico-note-add-blue.svg');
const searchIcon = require('@core/ui/static/images/ico-search-blue.svg');
const messageIcon = require('@core/ui/static/images/ico-messagerie-blue.svg');
const favoriteIcon = require('@core/ui/static/images/ico-fav-blue.svg');

const RegisterBlockWrapper = styled(Card)`
  background-color: ${colors.blue};
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  .wrapper {
    padding: 20px;
    .title {
      font-size: 20px;
      font-weight: 600;
      color: ${colors.white};
    }
    a {
      display: inline-block;
      background-color: ${colors.white};
      padding: 15px 65px;
      color: ${colors.blue};
      font-family: Poppins;
      font-size: 15px;
      font-weight: 500;
      border-radius: 4px;
      margin-top: 25px;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .wrapper {
      .title {
        font-size: 17px;
      }
      p {
        font-size: 14px;
      }
    }
  }
`;

const RegisterRowWrapper = styled.div`
  .flex-container {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 70px;
    margin: 45px auto 30px auto;
    max-width: 670px;
    .dashed {
      position: relative;
      width: 80%;
      border: 1px dashed ${colors.blueLight};
      top: -96px;
      z-index: -1;
    }
  }
  @media only screen and (max-width: 767px) {
    margin: 15px auto 30px auto;
  }
`;

const RegisterRowItemWrapper = styled.div`
  width: 115px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  div {
    max-width: 115px;
    margin-top: 15px;
    color: ${colors.white};
    font-size: 15px;
    font-weight: 600;
  }
`;

const BgCircle = styled.div`
  height: 70px;
  width: 70px;
  background-color: ${colors.blueLight2};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BgImage = styled.div`
  background-image: url(${BannerImage});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  height: 130px;
  border-radius: 8px;
`;

const RegisterBlock = ({ title, signup, link, isMobile, children }) => (
  <RegisterBlockWrapper>
    <div className="wrapper">
      <p className="title">{title}</p>
      <RegisterRowWrapper>
        <div className="flex-container">
          {children}
          {!isMobile ? <div className="dashed" /> : null}
        </div>
      </RegisterRowWrapper>
      <p className="signup">
        <Link href={link}>
          <a>{signup}</a>
        </Link>
      </p>
    </div>
    <BgImage />
  </RegisterBlockWrapper>
);

RegisterBlock.propTypes = {
  title: PropTypes.string,
  signup: PropTypes.string,
  link: PropTypes.string,
  isMobile: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

RegisterBlock.defaultProps = {};

const RegisterRowItem = ({ text, img, alt }) => (
  <RegisterRowItemWrapper>
    <BgCircle>
      <img src={img} alt={alt} height={36} width={36} />
    </BgCircle>
    <div>{text}</div>
  </RegisterRowItemWrapper>
);

RegisterRowItem.propTypes = {
  text: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
};

RegisterRowItem.defaultProps = {};

const RegisterBanner = ({ isMobile }) => {
  const { t } = useTranslation();

  return (
    <RegisterBlock
      title={t(`${process.env.APP_LOCALIZATION}:register.body.title`)}
      signup={t('home.register.btn')}
      link="/signup"
      isMobile={isMobile}
    >
      <RegisterRowItem
        img={publishIcon}
        alt="publish"
        text={t(`${process.env.APP_LOCALIZATION}:register.body.publish`)}
      />
      <RegisterRowItem
        img={searchIcon}
        alt="search"
        text={t(`${process.env.APP_LOCALIZATION}:register.body.search`)}
      />
      <RegisterRowItem
        img={messageIcon}
        alt="chat"
        text={t(`${process.env.APP_LOCALIZATION}:register.body.chat`)}
      />
      <RegisterRowItem
        img={favoriteIcon}
        alt="favorites"
        text={t(`${process.env.APP_LOCALIZATION}:register.body.favorites`)}
      />
    </RegisterBlock>
  );
};

RegisterBanner.propTypes = {
  isMobile: PropTypes.bool,
};

RegisterRowItem.defaultProps = {};

export default RegisterBanner;
