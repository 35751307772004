import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'reusecore/src/elements/Heading';
import PlaceholderImage from '@core/ui/static/images/categoryPlaceholder.svg';

const CategoryGridCard = ({
  title,
  postNumber,
  imageSrc,
  imageStyle,
  titleStyle,
  webp,
  sizes,
  postNumberContainer,
  postNumberStyle,
  hn,
  boxShadow,
  ...props
}) => {
  const imgSrc = imageSrc ? imageSrc : PlaceholderImage;
  return (
    <div {...props} style={{ boxShadow }}>
      {imgSrc && (
        <picture>
          <source
            srcSet={webp.srcSet}
            sizes={sizes ? sizes : null}
            type="image/webp"
          />
          <img
            src={imgSrc.src}
            srcSet={imgSrc.srcSet}
            sizes={sizes ? sizes : null}
            alt={title}
            width={160}
            style={imageStyle}
          />
        </picture>
      )}
      {title && <Heading as={hn} content={title} {...titleStyle} />}
      {postNumber && (
        <div style={postNumberContainer}>
          <span style={postNumberStyle}>{postNumber}</span>
        </div>
      )}
    </div>
  );
};

CategoryGridCard.propTypes = {
  title: PropTypes.string.isRequired,
  source: PropTypes.string,
  postNumber: PropTypes.string,
  hn: PropTypes.string,
  imageStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  postNumberStyle: PropTypes.object,
};

CategoryGridCard.defaultProps = {
  color: '#595959',
  bg: '#FFFFFF',
  ml: '0',
  mr: '0',
  boxShadow: 'none',
  hn: 'h2',
  titleStyle: {
    fontSize: '15px',
    fontWeight: 'normal',
    mb: '0',
    textAlign: 'center',
    mt: '10px',
  },
  imageStyle: {
    objectFit: 'cover',
    margin: 'auto',
    overflow: 'hidden',
  },
  postNumberContainer: {
    alignItems: 'center',
    display: 'grid',
    justifyContent: 'center',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  postNumberStyle: {
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: 20,
    fontWeight: 'bold',
    textShadow: '1px 1px 2px black',
  },
};

export default CategoryGridCard;
