import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import getRegie, {applyRevive} from '../helpers/getRegie';

const SERVER = 'https://diffusion.skazy.nc/www/delivery/asyncspc.php';

const RANDOM = Math.floor(Math.random() * 99999999999);

const SkazyAdvertising = props => {
  const {
    name = '',
    zone = {},
    height = null,
    containerStyle = {},
    width = null,
  } = props;

  const { n = '', id = '' } = zone;

  if (!n || !id) {
    return <></>;
  }

  const [customAd, setCustomAd] = useState(null);

  const customRegie = process.env.CUSTOM_REGIE === '1';

  const getContent = () => {
    if (customRegie) {
      return (
        <a
          aria-label="ad"
          href={url}
          target="_blank"
          rel="noreferrer noopener nofollow"
          style={{ display: 'flex' }}
        >
          <img
            border="0"
            alt=""
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            src={image}
          />
        </a>
      );
    } else {
      return (
        <ins data-revive-zoneid={id} data-revive-id={n}></ins>
        // <div dangerouslySetInnerHTML={{__html: customAd}} />
      );
    }
  };

  useEffect(() => {
    if (customRegie) {
      getRegie(name).then(ad => {
        setCustomAd(ad);
      });
    } else {
      applyRevive();
    }
  }, [customRegie]);

  useEffect(() => {
    window.setTimeout(() => {
      const s = document.querySelector('script.to-run');
      if (s) {
        s.className = '';
        Function(s.innerHTML).call(s);
      }
    }, 100)
  }, [customAd]);

  useEffect(() => {
    fetch(`${SERVER}?zones=${id}`)
      .then((response) => {
        return response.json();
      })
      .then((text) => {
        setCustomAd(text[0].html)
      })
      .catch((err) => {
        console.error(err)
      });
  }, [zone])

  if (
    customRegie === false &&
    (height === null || n === '' || width === null || id === '')
  )
    return null;
  if (
    customRegie === true &&
    (height === null || width === null || image === '')
  )
    return null;

  return (
    <div
      id={'ad_zone_' + RANDOM}
      style={{
        ...styles.container,
        ...containerStyle,
        width,
        height: height,
        maxHeight: height,
      }}
    >
      {getContent()}
    </div>
  );
};

SkazyAdvertising.propTypes = {
  name: PropTypes.string.isRequired,
  containerStyle: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  zone: PropTypes.exact({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    n: PropTypes.string,
  }),
};

export default SkazyAdvertising;

let styles = {
  container: {
    //backgroundColor: '#EEE',
    position: 'relative',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    //width    : '100%',
    //height   : '100%'
  },
};
