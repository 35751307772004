import React, { useState } from 'react';
import { Row } from 'react-styled-flexboxgrid';
import { IoIosSearch } from 'react-icons/io';
import { useTranslation } from 'next-i18next';
import InputSearch from '../../../components/InputSearch';
import styled from 'styled-components';
import useMediaQuery from '../../../helpers/mediaQuery';

const Search = styled(InputSearch)`
  box-shadow: 0 14px 25px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  color: #0b2f3f;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
`;

const SearchRow = styled(Row)`
  margin: 0;
  &.mobile {
    padding: 0 15px;
  }
`;

const TextSearch = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 1023px)');

  const [searchValue, setSearchValue] = useState('');

  const onChange = e => {
    setSearchValue(e.target.value);
  };

  return (
    <SearchRow className={isMobile ? 'mobile' : ''}>
      <div style={{ width: '100%' }}>
        <InputSearch
          changed={onChange}
          value={searchValue}
          placeholder={t('home.search.placeholder')}
          buttonIcon={<IoIosSearch size={20} />}
          buttonText={t('home.search.button')}
          searchStyle={{
            backgroundColor: '#F3F3F3',
          }}
        />
      </div>
    </SearchRow>
  );
};

export default TextSearch;
