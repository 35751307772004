import React from 'react';
import { BottomSeoWrapper } from './Index.style';
import { useTranslation } from 'next-i18next';
import { Row, Col } from 'react-styled-flexboxgrid';

const Seo = () => {
  const { t } = useTranslation();

  return (
    <BottomSeoWrapper>
      <Row className="title">
        <Col xs={12} sm={6}>
          <h1>
            {t('common:home.seo.title')}
            {t(`${process.env.APP_LOCALIZATION}:country.prefixIn`)}
            {t(`${process.env.APP_LOCALIZATION}:country.label`)}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <h2>{t(`${process.env.APP_LOCALIZATION}:home.body.title1`)}</h2>
          <p
            style={{ textAlign: 'justify' }}
            dangerouslySetInnerHTML={{
              __html: t(`${process.env.APP_LOCALIZATION}:home.body.seo`),
            }}
          />
          <p
            style={{ textAlign: 'justify' }}
            dangerouslySetInnerHTML={{ __html: t('home.seo.content1_2') }}
          />
          {/*<p>{t(`${process.env.APP_LOCALIZATION}:home.body.content1_3`)}</p>*/}
        </Col>
        <Col xs={0} sm={1} />
        <Col xs={12} sm={5}>
          <h2>{t('home.seo.title2')}</h2>
          <p
            style={{ textAlign: 'justify' }}
            dangerouslySetInnerHTML={{
              __html: t(`${process.env.APP_LOCALIZATION}:home.body.content2`),
            }}
          />
          <h2>{t('home.seo.title3')}</h2>
          <p
            style={{ textAlign: 'justify' }}
            dangerouslySetInnerHTML={{ __html: t('home.seo.content3') }}
          />
        </Col>
      </Row>
    </BottomSeoWrapper>
  );
};
export default Seo;
