import { db } from './firebaseHelper';

export const applyRevive = () => {
  if (window.reviveAsync && window.reviveAsync[process.env.SKAZY_AD_EXT_N]) {
    const insList = document.querySelectorAll(`ins[data-revive-id='${process.env.SKAZY_AD_EXT_N}']`);
    for (let i = 0; i < insList.length; i++) {
      const ins = insList[i];
      // remove data-revive-loaded if not child detected
      if (!ins.hasChildNodes()) {
        ins.removeAttribute('data-revive-loaded');
      }
    }
    window.reviveAsync[process.env.SKAZY_AD_EXT_N].refresh()
  }
}

const getRegie = async zoneName => {
  const key = 'regie_' + zoneName;

  const getRandom = max => {
    const min = 0;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getData = () => {
    return db
      .collection('regie')
      .doc(zoneName)
      .get()
      .then(querySnapshot => {
        const { exists } = querySnapshot;
        if (exists) {
          const data = querySnapshot.data();
          const { active = false, ads = [], url = null, image = null } = data;

          if (active) {
            let current;
            if (ads && ads.length > 0) {
              current = getRandom(ads.length - 1);
            }
            sessionStorage.setItem(
              key,
              JSON.stringify({ ads, url, image, current })
            );
            return;
          }
        }
        sessionStorage.setItem(key, null);
      })
      .catch(error => {
        console.error('Error getting pub => ', error);
        sessionStorage.setItem(key, null);
      });
  };

  let value = JSON.parse(sessionStorage.getItem(key));
  if (value === null) {
    await getData();
    value = JSON.parse(sessionStorage.getItem(key));
  }

  const { ads = [], image = '', url = '', current = 0 } = value || {};

  if (ads && ads.length > 0) {
    let newCurrent = current + 1;
    if (newCurrent > ads.length - 1) {
      newCurrent = 0;
    }
    //
    sessionStorage.setItem(
      key,
      JSON.stringify({ ...value, current: newCurrent })
    );
    return { image: ads[current].image, url: ads[current].url };
  }

  return { image, url };
};

export default getRegie;
