import styled from 'styled-components';

export const BottomSeoWrapper = styled('div')`
  margin-top: 70px;
  padding: 0 40px;
  background-color: #fff;
  .title {
    margin-bottom: 30px;
  }
  h1 {
    margin-top: 40px;
    color: #0b2f3f;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 25px;
    }
  }
  h2 {
    color: #0b2f3f;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
  }
  p {
    color: #595959;
    font-family: Poppins;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;
