import gql from 'graphql-tag';

export const GET_CATEGORIES = gql`
  query getCategories($LIMIT: Int) {
    categories(limit: $LIMIT) {
      data {
        id
        slug
        name
        image {
          url
        }
        posts {
          total
        }
      }
      total
    }
  }
`;

export const GET_CATEGORIES_FOR_DROPDOWN = gql`
  query GetCategories($limit: Int!, $string: String) {
    categories(limit: $limit, string: $string) {
      data {
        id
        slug
        name
      }
      total
    }
  }
`;

export const GET_CATEGORIES_NUMBERS = gql`
  query GetCategoriesNumbers($categories: [String!]!) {
    categoriesNumbers(categories: $categories) {
      data {
        name
        number
      }
    }
  }
`;
